<template>
  <div class="load-more relative" v-if="morepage || hasmore">
    <div class="load-more-content" tcenter dpadding>
      <a v-if="!loading" button @click="$emit('more')">
        <span>{{ label }}</span>
      </a>
      <iconic v-else :name="`${spinner || 'gloading'}`" />
    </div>
  </div>
</template>

<script>
export default {
  props: ["morepage", "hasmore", "label", "loading", "spinner"],
};
</script>

<style lang="scss" scoped>
.load-more {
  display: flex;
  justify-content: center;
  user-select: none;
  .iconic {
    font-size: 180%;
  }
  [button] {
    display: inline-flex;
    border: solid 1px;
    padding: $mpadding/2 $mpadding;
    border-radius: 2pc;
    align-self: center;
    cursor: pointer;
    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}
</style>
